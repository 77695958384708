@charset "utf-8";

/* 여백 초기화 */
body,
div,
ul,
li,
dl,
dd,
dt,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
input,
fieldset,
legend,
p,
select,
table,
th,
td,
tr,
textarea,
button,
form {
    margin: 0;
    padding: 0;
    border-collapse: collapse;
}

/* a 링크 초기화 */
a {
    color: #222;
    text-decoration: none;
}
a:hover {
    color: #390;
}

/* 폰트 초기화 */
body,
input,
textarea,
select,
button,
table {
    font-family: "Nanum Gothic", AppleSDGothicNeo-Regular, "Malgun Gothic",
        "맑은 고딕", dotum, "돋움", sans-serif;
    color: #222;
    font-size: 13px;
    line-height: 1.5;
}

/* 블릿기호 초기화 */
dl,
ul,
li,
ol,
menu {
    list-style: none;
}

/* 제목 태그 초기화 */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 13px;
    font-weight: normal;
}

/* 테두리 초기화 */
img {
    border: 0 none;
}

/* IR 효과 */
.ir_pm {
    display: block;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    text-indent: -9999px;
} /* 의미있는 이미지의 대체 텍스트를 제공하는 경우(Phark Method) */
.ir_wa {
    display: block;
    overflow: hidden;
    position: relative;
    z-index: -1;
    width: 100%;
    height: 100%;
} /* 의미있는 이미지의 대체 텍스트로 이미지가 없어도 대체 텍스트를 보여주고자 할 때(WA IR) */
.ir_su {
    overflow: hidden;
    position: absolute;
    width: 0;
    height: 0;
    line-height: 0;
    text-indent: -9999px;
} /* 대체 텍스트가 아닌 접근성을 위한 숨김 텍스트를 제공할 때 */

/* margin, padding */
.mt10 {
    margin-top: 10px !important;
}
.mt15 {
    margin-top: 15px !important;
}
.mt20 {
    margin-top: 20px !important;
}
.mt25 {
    margin-top: 25px !important;
}
.mt30 {
    margin-top: 30px !important;
}
.mt35 {
    margin-top: 35px !important;
}
.mt40 {
    margin-top: 40px !important;
}
.mt45 {
    margin-top: 45px !important;
}
.mt50 {
    margin-top: 50px !important;
}

.MuiInput-root {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 
*::-webkit-scrollbar {
    width: 0.15em;
    display: none;
}

*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    outline: 1px solid slategrey;
} */

.MuiList-padding::-webkit-scrollbar {
    width: 0.15em;
    /* display: none; */
}

.MuiTableContainer-root::-webkit-scrollbar {
    height: 0.3vh;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar {
    width: 0.15em;
    height: 0.5vh;
}

/* ===== ag-grid(Content) ===== */

.grid-changed-backcolor {
    font-weight: bold;
    background-color: red;
}

.ag-root ::-webkit-scrollbar {
    position: fixed;
    width: 1em;
    height: 1em;
}

.ag-root ::-webkit-scrollbar-button {
    display: none;
}

.ag-root ::-webkit-scrollbar-track-piece {
    background: #1E2430;
}

.ag-root ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #388DFC;
}
