/* 전체를 감싸는 component 적용 class */

/* PC (해상도 1024px ~ ) */
/* @media all and (min-width:1024px) {} */

/* tablet horizontal (해상도 768px ~ 1023px) */
/* @media all and (min-width:768px) and (max-width:1023px) {} */

/* mobile horizontal / tablet vertical (해상도 480px ~ 767px) */
/* @media all and (min-width:480px) and (max-width:767px) {} */

/* mobile horizontal / tablet vertical (해상도 ~ 479px) */
/* @media all and (max-width:479px) {} */

/* 폰트 적용 */
@font-face {
	font-family: "digital-num";
	src: url("./../Assets/Fonts/Digital_font.ttf");
}

main {
	width: 100%;
	/* height: 100vh; */
}

.wrap {
	/* width: 100%;
    height: 100vh; */
}

/* 스크롤바 없애는 class */
.no-scroll {
	-ms-overflow-style: none;
	/* IE, Edge */
	scrollbar-width: none;
	/* Firefox */
}

/* 스크롤바 없애는 class */
.no-scroll::-webkit-scrollbar {
	display: none;
	/* Chrome, Safari, Opera */
}

/* 전체 드래그 금지 class */
.draggable-all {
	-webkit-user-select: all;
	-moz-user-select: all;
	-ms-user-select: all;
	user-select: all;
}

/* text 드래그 금지 class */
.draggable-text {
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}

/* ========================= login ========================= */
.login-iframe {
	/* width: 450px;
    height: 260px;
    margin-top: 80px; */
	width: 800px;
	height: 450px;
	margin-top: 30px;
	border: 0;
}

/* ========================= ag-grid========================= */

/* .ag-center-cols-viewport {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.ag-center-cols-viewport::-webkit-scrollbar {
    display: none;
}

.ag-grid-back-red {
    background-color: red;
}

.ag-grid-back-blue {
    background-color: blue;
} */

.zoomin {
	filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(249deg)
		brightness(106%) contrast(101%);
}

.zoomout {
	filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(249deg)
		brightness(106%) contrast(101%);
}

/* ========================= Empty box========================= */
/* Empty Box */
.empty_box {
	width: 100%;
	height: 64px;
}

@media (max-width: 600px) {
	.empty_box {
		height: 56px;
	}
}

/* ========================= Icon Button ========================= */
.reloadIconButtons {
	transition: all ease 1s;
}

.reloadIconButtons:hover {
	transform: rotate(-180deg);
}

/* ========================= datepicker ========================= */
.datepicker-custom-input {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 150px;
	padding: 5px;
	cursor: pointer;
	font-size: 13px;
	text-align: center;
	border: 1px solid #cfd9e0;
	border-radius: 5px;
	color: #ffffff;
	background-color: #1e2430;
}

.datepicker-custom-input2 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90px;
	padding: 5px;
	cursor: pointer;
	font-size: 13px;
	text-align: center;
	border: 1px solid #cfd9e0;
	border-radius: 5px;
	color: #ffffff;
	background-color: #1e2430;
}

.datepicker-custom-input3 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 55px;
	padding: 5px;
	cursor: pointer;
	font-size: 13px;
	text-align: center;
	border: 1px solid #cfd9e0;
	border-radius: 5px;
	color: #ffffff;
	background-color: #1e2430;
}

.datepicker-custom-input4 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 55px;
	padding: 5px;
	cursor: pointer;
	font-size: 13px;
	text-align: center;
	border: 1px solid #cfd9e0;
	border-radius: 5px;
	color: #ffffff;
	background-color: #1e2430;
}

.react-datepicker__tab-loop > * {
	z-index: 999999999;
}

/* ========================= gradient-box ========================= */

.gradient-box {
	width: 50px;
	height: 50px;
	margin: 0px;
	margin-bottom: 5px;
	padding: 0px;
	padding-top: 2.5px;
	text-align: center;
}

/* The animation starts here */
.gradient-box {
	position: relative;
}

.gradient-box:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 8px;
	background: linear-gradient(120deg, #00f260, #0575e6, #00f260);
	/* background: linear-gradient(120deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
	#0000ff, #00ff00,#ffff00, #ff0000); */
	background-size: 300% 300%;
	clip-path: polygon(
		0% 100%,
		3px 100%,
		3px 3px,
		calc(100% - 3px) 3px,
		calc(100% - 3px) calc(100% - 3px),
		3px calc(100% - 3px),
		3px 100%,
		100% 100%,
		100% 0%,
		0% 0%
	);
}

.gradient-box.in:after {
	animation: frame-enter 1s forwards ease-in-out reverse,
		gradient-animation 4s ease-in-out infinite;
}

/* motion */
@keyframes gradient-animation {
	0% {
		background-position: 15% 0%;
	}
	50% {
		background-position: 85% 100%;
	}
	100% {
		background-position: 15% 0%;
	}
}

@keyframes frame-enter {
	0% {
		clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) calc(100% - 3px),
			3px calc(100% - 3px),
			3px 100%,
			100% 100%,
			100% 0%,
			0% 0%
		);
	}
	25% {
		clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) calc(100% - 3px),
			calc(100% - 3px) calc(100% - 3px),
			calc(100% - 3px) 100%,
			100% 100%,
			100% 0%,
			0% 0%
		);
	}
	50% {
		clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) 3px,
			100% 0%,
			0% 0%
		);
	}
	75% {
		-webkit-clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 3px,
			3px 3px,
			3px 3px,
			3px 3px,
			3px 3px,
			3px 3px,
			3px 0%,
			0% 0%
		);
	}
	100% {
		-webkit-clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			0% 100%
		);
	}
}

.gradient-box-web {
	width: 80px;
	height: 80px;
	margin: 0px;
	margin-bottom: 5px;
	padding: 0px;
	padding-top: 2.5px;
	text-align: center;
}

/* The animation starts here */
.gradient-box-web {
	position: relative;
}

.gradient-box-web:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 8px;
	background: linear-gradient(120deg, #00f260, #0575e6, #00f260);
	/* background: linear-gradient(120deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
	#0000ff, #00ff00,#ffff00, #ff0000); */
	background-size: 300% 300%;
	clip-path: polygon(
		0% 100%,
		3px 100%,
		3px 3px,
		calc(100% - 3px) 3px,
		calc(100% - 3px) calc(100% - 3px),
		3px calc(100% - 3px),
		3px 100%,
		100% 100%,
		100% 0%,
		0% 0%
	);
}

.gradient-box-web.in:after {
	animation: frame-enter 1s forwards ease-in-out reverse,
		gradient-animation 4s ease-in-out infinite;
}

/* motion */
@keyframes gradient-animation {
	0% {
		background-position: 15% 0%;
	}
	50% {
		background-position: 85% 100%;
	}
	100% {
		background-position: 15% 0%;
	}
}

@keyframes frame-enter {
	0% {
		clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) calc(100% - 3px),
			3px calc(100% - 3px),
			3px 100%,
			100% 100%,
			100% 0%,
			0% 0%
		);
	}
	25% {
		clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) calc(100% - 3px),
			calc(100% - 3px) calc(100% - 3px),
			calc(100% - 3px) 100%,
			100% 100%,
			100% 0%,
			0% 0%
		);
	}
	50% {
		clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) 3px,
			100% 0%,
			0% 0%
		);
	}
	75% {
		-webkit-clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 3px,
			3px 3px,
			3px 3px,
			3px 3px,
			3px 3px,
			3px 3px,
			3px 0%,
			0% 0%
		);
	}
	100% {
		-webkit-clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			0% 100%
		);
	}
}

/* ========================= error-box ========================= */

.error-box {
	padding: 8px;
}

/* The animation starts here */
.error-box {
	position: relative;
}

.error-box:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 8px;
	background: linear-gradient(120deg, #ffff00, #ff0000, #ffff00);
	/* background: linear-gradient(120deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
	#0000ff, #00ff00,#ffff00, #ff0000); */
	background-size: 300% 300%;
	clip-path: polygon(
		0% 100%,
		3px 100%,
		3px 3px,
		calc(100% - 3px) 3px,
		calc(100% - 3px) calc(100% - 3px),
		3px calc(100% - 3px),
		3px 100%,
		100% 100%,
		100% 0%,
		0% 0%
	);
}

.error-box.error-in:after {
	animation: frame-enter 1s forwards ease-in-out reverse,
		gradient-animation 4s ease-in-out infinite;
}

/* motion */
@keyframes gradient-animation {
	0% {
		background-position: 15% 0%;
	}
	50% {
		background-position: 85% 100%;
	}
	100% {
		background-position: 15% 0%;
	}
}

@keyframes frame-enter {
	0% {
		clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) calc(100% - 3px),
			3px calc(100% - 3px),
			3px 100%,
			100% 100%,
			100% 0%,
			0% 0%
		);
	}
	25% {
		clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) calc(100% - 3px),
			calc(100% - 3px) calc(100% - 3px),
			calc(100% - 3px) 100%,
			100% 100%,
			100% 0%,
			0% 0%
		);
	}
	50% {
		clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) 3px,
			100% 0%,
			0% 0%
		);
	}
	75% {
		-webkit-clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 3px,
			3px 3px,
			3px 3px,
			3px 3px,
			3px 3px,
			3px 3px,
			3px 0%,
			0% 0%
		);
	}
	100% {
		-webkit-clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			0% 100%
		);
	}
}

.normal-box {
	padding: 8px;
}

/* The animation starts here */
.normal-box {
	position: relative;
}

.normal-box:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 8px;
	background: linear-gradient(120deg, #00f260, #0575e6, #00f260);
	/* background: linear-gradient(120deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
	#0000ff, #00ff00,#ffff00, #ff0000); */
	background-size: 300% 300%;
	clip-path: polygon(
		0% 100%,
		3px 100%,
		3px 3px,
		calc(100% - 3px) 3px,
		calc(100% - 3px) calc(100% - 3px),
		3px calc(100% - 3px),
		3px 100%,
		100% 100%,
		100% 0%,
		0% 0%
	);
}

.normal-box.normal-in:after {
	animation: frame-enter 1s forwards ease-in-out reverse,
		gradient-animation 4s ease-in-out infinite;
}

/* motion */
@keyframes gradient-animation {
	0% {
		background-position: 15% 0%;
	}
	50% {
		background-position: 85% 100%;
	}
	100% {
		background-position: 15% 0%;
	}
}

@keyframes frame-enter {
	0% {
		clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) calc(100% - 3px),
			3px calc(100% - 3px),
			3px 100%,
			100% 100%,
			100% 0%,
			0% 0%
		);
	}
	25% {
		clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) calc(100% - 3px),
			calc(100% - 3px) calc(100% - 3px),
			calc(100% - 3px) 100%,
			100% 100%,
			100% 0%,
			0% 0%
		);
	}
	50% {
		clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) 3px,
			calc(100% - 3px) 3px,
			100% 0%,
			0% 0%
		);
	}
	75% {
		-webkit-clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 3px,
			3px 3px,
			3px 3px,
			3px 3px,
			3px 3px,
			3px 3px,
			3px 0%,
			0% 0%
		);
	}
	100% {
		-webkit-clip-path: polygon(
			0% 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			3px 100%,
			0% 100%
		);
	}
}

.schedule-list:hover {
	background-color: #4a5263;
}
